import React from 'react'
import Hararchy from './Hararchy'
import './App.css'

function App() {
  return (
    <div style={{
      background:'#FFEFC5',
      width:'100vw',
      height:'100vh'
    }}>

      <img style={{
        position:'fixed',
        left:'-20vw',
        width:'40vw',
        top:'10vh'
      }} src='bg.png' alt='img'/>

<img style={{
        position:'fixed',
        right:'-20vw',
        width:'40vw',
        top:'10vh'
      }} src='bg.png' alt='img'/>

      <Hararchy/>
      
    </div>
  )
}

export default App
