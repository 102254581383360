import React,{useState,useCallback} from 'react'

import { ReactFlow } from '@xyflow/react';
 
import '@xyflow/react/dist/style.css';

const nodeDefaults = {
    style: {
      borderRadius: 10,
      fontSize:16,
      width: 150,
      height: 50,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgb(226, 185, 124)'
    },
  };
 
  const initialNodes = [
    {id: '1',position: { x: (window.innerWidth/2)-75, y: 0 },data: {label: 'झींगू पटेल',},style: {
        borderRadius: 10,
        fontSize:16,
        width: 150,
        height: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgb(226, 185, 124)'
      }},
    {id: '2',position: { x: (window.innerWidth/2)-300, y: 200 },data: {label: 'इसना',},...nodeDefaults},
    {id: '3',position: { x: (window.innerWidth/2)+150, y: 200 },data: {label: 'कडू',},...nodeDefaults},
    {id: '4',position: { x: (window.innerWidth/2)-600, y: 200 },data: {label: 'तानू',},...nodeDefaults},
    {id: '5',position: { x: (window.innerWidth/2)+450, y: 200 },data: {label: 'बुधा',},...nodeDefaults},


    {id: '179',position: { x: (window.innerWidth/2)+700, y: 400 },data: {label: 'नकटू',},...nodeDefaults},

    {id: '6',position: { x: (window.innerWidth/2)-900, y: 400 },data: {label: 'जयराम',},...nodeDefaults},
    {id: '7',position: { x: (window.innerWidth/2)-600, y: 600 },data: {label: 'लोढू',},...nodeDefaults},
    {id: '8',position: { x: (window.innerWidth/2)-900, y: 600 },data: {label: 'बक्सी',},...nodeDefaults},
    {id: '9',position: { x: (window.innerWidth/2)-500, y: 400 },data: {label: 'शिवराम',},...nodeDefaults},
    {id: '10',position: { x: (window.innerWidth/2)-300, y: 400 },data: {label: 'केउ',},...nodeDefaults},


    {id: '11',position: { x: (window.innerWidth/2)-100, y: 600 },data: {label: 'नंदराम',},...nodeDefaults},
    {id: '12',position: { x: (window.innerWidth/2)+100, y: 600 },data: {label: 'काशीराम',},...nodeDefaults},
    {id: '13',position: { x: (window.innerWidth/2)+430, y: 1000 },data: {label: 'गणेश',},...nodeDefaults},
    {id: '14',position: { x: (window.innerWidth/2)-300, y: 600 },data: {label: 'गोविंदराम',},...nodeDefaults},


    {id: '15',position: { x: (window.innerWidth/2)+1300, y: 800 },data: {label: 'सेगो',},...nodeDefaults},
    {id: '16',position: { x: (window.innerWidth/2)+700, y: 600 },data: {label: 'गणपत',},...nodeDefaults},
    {id: '17',position: { x: (window.innerWidth/2)+500, y: 600 },data: {label: 'नस्तो',},...nodeDefaults},
    {id: '18',position: { x: (window.innerWidth/2)+1300, y: 600 },data: {label: 'उमराव',},...nodeDefaults},


    {id: '19',position: { x: (window.innerWidth/2)-1200, y: 800 },data: {label: 'दुर्गा',},...nodeDefaults},
    {id: '20',position: { x: (window.innerWidth/2)-1000, y: 800 },data: {label: 'मंगल',},...nodeDefaults},

    {id: '21',position: { x: (window.innerWidth/2)-800, y: 800 },data: {label: 'परसराम',},...nodeDefaults},

    {id: '22',position: { x: (window.innerWidth/2)-900, y: 1000 },data: {label: 'महादू',},...nodeDefaults},
    {id: '23',position: { x: (window.innerWidth/2)-700, y: 1000 },data: {label: 'दादू',},...nodeDefaults},


    {id: '24',position: { x: (window.innerWidth/2)-800, y: 1200 },data: {label: 'मानकलाल',},...nodeDefaults},


    {id: '25',position: { x: (window.innerWidth/2)-900, y: 1400 },data: {label: 'बैजनाथ',},...nodeDefaults},
    {id: '26',position: { x: (window.innerWidth/2)-1100, y: 1400 },data: {label: 'गजानन्द',},...nodeDefaults},


    {id: '27',position: { x: (window.innerWidth/2)-1100, y: 1200 },data: {label: 'मंन्साराम',},...nodeDefaults},
    {id: '28',position: { x: (window.innerWidth/2)-1300, y: 1200 },data: {label: 'भोला',},...nodeDefaults},
    {id: '29',position: { x: (window.innerWidth/2)-1500, y: 1200 },data: {label: 'दरसन',},...nodeDefaults},


    {id: '30',position: { x: (window.innerWidth/2)-1300, y: 1400 },data: {label: 'शिवसिंग',},...nodeDefaults},
    {id: '31',position: { x: (window.innerWidth/2)-1500, y: 1400 },data: {label: 'मानसिंग',},...nodeDefaults},


    {id: '32',position: { x: (window.innerWidth/2)-1100, y: 1000 },data: {label: 'रंगलाल',},...nodeDefaults},


    {id: '33',position: { x: (window.innerWidth/2)-1400, y: 1000 },data: {label: 'किशन',},...nodeDefaults},
    {id: '34',position: { x: (window.innerWidth/2)-1700, y: 1000 },data: {label: 'भदूदू',},...nodeDefaults},


    {id: '35',position: { x: (window.innerWidth/2)-1700, y: 1200 },data: {label: 'रामसिंग',},...nodeDefaults},


    {id: '36',position: { x: (window.innerWidth/2)-2000, y: 1400 },data: {label: 'कोमल',},...nodeDefaults},
    {id: '37',position: { x: (window.innerWidth/2)-2000, y: 1600 },data: {label: 'नारायण',},...nodeDefaults},
    {id: '38',position: { x: (window.innerWidth/2)-1700, y: 1400 },data: {label: 'जगदिश',},...nodeDefaults},


    {id: '39',position: { x: (window.innerWidth/2)-2500, y: 1200 },data: {label: 'चैनसिंग',},...nodeDefaults},
    {id: '40',position: { x: (window.innerWidth/2)-2300, y: 1200 },data: {label: 'तेजराम',},...nodeDefaults},
    {id: '41',position: { x: (window.innerWidth/2)-2100, y: 1200 },data: {label: 'धनसिंग',},...nodeDefaults},
    {id: '42',position: { x: (window.innerWidth/2)-1900, y: 1200 },data: {label: 'माधवसिंग',},...nodeDefaults},


    {id: '43',position: { x: (window.innerWidth/2)-2200, y: 1400 },data: {label: 'प्रहलाद',},...nodeDefaults},


    {id: '44',position: { x: (window.innerWidth/2)-2400, y: 1400 },data: {label: 'शिवकुमार',},...nodeDefaults},

    
    
    {id: '45',position: { x: (window.innerWidth/2)-2800, y: 1400 },data: {label: 'दिलीप',},...nodeDefaults},
    {id: '46',position: { x: (window.innerWidth/2)-2800, y: 1600 },data: {label: 'विजय',},...nodeDefaults},
    {id: '47',position: { x: (window.innerWidth/2)-2600, y: 1600 },data: {label: 'अजय',},...nodeDefaults},

    // second pushte

    {id: '48',position: { x: (window.innerWidth/2)+700, y: 800 },data: {label: 'सत्तू',},...nodeDefaults},
    {id: '49',position: { x: (window.innerWidth/2)+500, y: 800 },data: {label: 'दौलत',},...nodeDefaults},

    {id: '50',position: { x: (window.innerWidth/2)+900, y: 800 },data: {label: 'रामजी',},...nodeDefaults},
    {id: '51',position: { x: (window.innerWidth/2)+1100, y: 800 },data: {label: 'कारू',},...nodeDefaults},


    {id: '52',position: { x: (window.innerWidth/2)+900, y: 1000 },data: {label: 'रामबगस',},...nodeDefaults},
    {id: '53',position: { x: (window.innerWidth/2)+1100, y: 1000 },data: {label: 'फोगल',},...nodeDefaults},
    {id: '54',position: { x: (window.innerWidth/2)+1300, y: 1000 },data: {label: 'रामकिशन',},...nodeDefaults},
    {id: '55',position: { x: (window.innerWidth/2)+1500, y: 1000 },data: {label: 'शिवचरण',},...nodeDefaults},
    {id: '56',position: { x: (window.innerWidth/2)+1700, y: 1000 },data: {label: 'रामचंन',},...nodeDefaults},


    {id: '57',position: { x: (window.innerWidth/2)+1500, y: 800 },data: {label: 'अडकन',},...nodeDefaults},
    {id: '58',position: { x: (window.innerWidth/2)+1700, y: 800 },data: {label: 'दिलबगश',},...nodeDefaults},
    {id: '59',position: { x: (window.innerWidth/2)+1900, y: 800 },data: {label: 'लडकन',},...nodeDefaults},


    {id: '60',position: { x: (window.innerWidth/2)+1900, y: 1000 },data: {label: 'भैयालाल',},...nodeDefaults},


    {id: '61',position: { x: (window.innerWidth/2)+1300, y: 1200 },data: {label: 'ईश्वरी',},...nodeDefaults},
    {id: '62',position: { x: (window.innerWidth/2)+900, y: 1200 },data: {label: 'नारायण',},...nodeDefaults},
    {id: '63',position: { x: (window.innerWidth/2)+1200, y: 1400 },data: {label: 'मोहनलाल',},...nodeDefaults},
    {id: '64',position: { x: (window.innerWidth/2)+1000, y: 1400 },data: {label: 'द्वारका',},...nodeDefaults},


    {id: '65',position: { x: (window.innerWidth/2)+1400, y: 1400 },data: {label: 'उमाशंकर',},...nodeDefaults},


    {id: '66',position: { x: (window.innerWidth/2)+1600, y: 1200 },data: {label: 'कामता प्रसाद',},...nodeDefaults},
    {id: '67',position: { x: (window.innerWidth/2)+1800, y: 1200 },data: {label: 'दरयाव',},...nodeDefaults},
    {id: '68',position: { x: (window.innerWidth/2)+2000, y: 1200 },data: {label: 'तामसिंग',},...nodeDefaults},


    {id: '69',position: { x: (window.innerWidth/2)+1600, y: 1400 },data: {label: 'मेखसिंह भारत',},...nodeDefaults},


    {id: '70',position: { x: (window.innerWidth/2)+1800, y: 1400 },data: {label: 'सुखलाल',},...nodeDefaults},
    {id: '71',position: { x: (window.innerWidth/2)+2000, y: 1400 },data: {label: 'तोपसिंग',},...nodeDefaults},


    {id: '72',position: { x: (window.innerWidth/2)+1500, y: 1600 },data: {label: 'फूलचंद',},...nodeDefaults},
    {id: '73',position: { x: (window.innerWidth/2)+1700, y: 1600 },data: {label: 'दुलीचंद',},...nodeDefaults},
    {id: '74',position: { x: (window.innerWidth/2)+1900, y: 1600 },data: {label: 'चंदूलाल',},...nodeDefaults},
    {id: '75',position: { x: (window.innerWidth/2)+2100, y: 1600 },data: {label: 'देवेंन्द्रसिंग',},...nodeDefaults},


    {id: '76',position: { x: (window.innerWidth/2)+1300, y: 1600 },data: {label: 'झामसिंग',},...nodeDefaults},
    {id: '77',position: { x: (window.innerWidth/2)+1100, y: 1600 },data: {label: 'दिगम्बर',},...nodeDefaults},


    {id: '78',position: { x: (window.innerWidth/2)+2000, y: 1800 },data: {label: 'हेमन्त कुमार',},...nodeDefaults},

    {id: '180',position: { x: (window.innerWidth/2)+800, y: 1400 },data: {label: 'ईश्वरदयाल',},...nodeDefaults},

    {id: '79',position: { x: (window.innerWidth/2)+900, y: 1600 },data: {label: 'बैजनाथ',},...nodeDefaults},


    {id: '80',position: { x: (window.innerWidth/2)+380, y: 1200 },data: {label: 'तेजराम',},...nodeDefaults},

    {id: '81',position: { x: (window.innerWidth/2)+400, y: 1400 },data: {label: 'विश्वेश्वर',},...nodeDefaults},
    {id: '82',position: { x: (window.innerWidth/2)+600, y: 1400 },data: {label: 'जागेश्वर',},...nodeDefaults},



    {id: '83',position: { x: (window.innerWidth/2)+1100, y: 1800 },data: {label: 'ख्यालसिंह',},...nodeDefaults},
    {id: '84',position: { x: (window.innerWidth/2)+1300, y: 1800 },data: {label: 'ढालसिंह',},...nodeDefaults},
    {id: '85',position: { x: (window.innerWidth/2)+1500, y: 1800 },data: {label: 'खड़गसिंह',},...nodeDefaults},
    {id: '86',position: { x: (window.innerWidth/2)+1700, y: 1800 },data: {label: 'मदन',},...nodeDefaults},

    {id: '87',position: { x: (window.innerWidth/2)+1800, y: 2000 },data: {label: 'आनंदसिंह',},...nodeDefaults},
    {id: '88',position: { x: (window.innerWidth/2)+2000, y: 2000 },data: {label: 'दीलिपसिंह',},...nodeDefaults},
    {id: '89',position: { x: (window.innerWidth/2)+2200, y: 2000 },data: {label: 'देवकीनंदन',},...nodeDefaults},
    {id: '90',position: { x: (window.innerWidth/2)+2400, y: 2000 },data: {label: 'गजानंद',},...nodeDefaults},

    {id: '91',position: { x: (window.innerWidth/2)+1400, y: 2000 },data: {label: 'दौलतसिंह',},...nodeDefaults},
    {id: '92',position: { x: (window.innerWidth/2)+1600, y: 2000 },data: {label: 'शिवसिंग',},...nodeDefaults},

    {id: '93',position: { x: (window.innerWidth/2)+1600, y: 2200 },data: {label: 'भूपेन्द्र',},...nodeDefaults},
    {id: '94',position: { x: (window.innerWidth/2)+1800, y: 2200 },data: {label: 'मनोहर',},...nodeDefaults},
    {id: '95',position: { x: (window.innerWidth/2)+2000, y: 2200 },data: {label: 'कन्हैया',},...nodeDefaults},
    {id: '96',position: { x: (window.innerWidth/2)+2200, y: 2200 },data: {label: 'गौरव',},...nodeDefaults},
    {id: '97',position: { x: (window.innerWidth/2)+2400, y: 2200 },data: {label: 'पारस',},...nodeDefaults},
    {id: '98',position: { x: (window.innerWidth/2)+2600, y: 2200 },data: {label: 'एकांत',},...nodeDefaults},
    {id: '99',position: { x: (window.innerWidth/2)+2800, y: 2200 },data: {label: 'डुलेन्द्र',},...nodeDefaults},

    {id: '100',position: { x: (window.innerWidth/2)+700, y: 1600 },data: {label: 'महेश्वर',},...nodeDefaults},
    {id: '101',position: { x: (window.innerWidth/2)+900, y: 1800 },data: {label: 'गौरव',},...nodeDefaults},


    {id: '102',position: { x: (window.innerWidth/2)-800, y: 1600 },data: {label: 'मुंडाजी',},...nodeDefaults},
    {id: '103',position: { x: (window.innerWidth/2)-600, y: 1600 },data: {label: 'उदाजी',},...nodeDefaults},
    {id: '104',position: { x: (window.innerWidth/2)-400, y: 1600 },data: {label: 'उमेदसिंह',},...nodeDefaults},
    {id: '105',position: { x: (window.innerWidth/2)-200, y: 1600 },data: {label: 'अमरुत',},...nodeDefaults},
    {id: '106',position: { x: (window.innerWidth/2), y: 1600 },data: {label: 'बालाराम',},...nodeDefaults},

    {id: '107',position: { x: (window.innerWidth/2)+200, y: 1800 },data: {label: 'रामजी',},...nodeDefaults},
    {id: '108',position: { x: (window.innerWidth/2)+300, y: 1600 },data: {label: 'साबाजी',},...nodeDefaults},


    {id: '109',position: { x: (window.innerWidth/2)+700, y: 1800 },data: {label: 'हृदयराम',},...nodeDefaults},
    {id: '110',position: { x: (window.innerWidth/2)+1300, y: 2200 },data: {label: 'फागुराम',},...nodeDefaults},

    {id: '181',position: { x: (window.innerWidth/2)+850, y: 2200 },data: {label: 'एका',},...nodeDefaults},
    {id: '182',position: { x: (window.innerWidth/2)+1050, y: 2200 },data: {label: 'प्रेमलाल',},...nodeDefaults},

    {id: '183',position: { x: (window.innerWidth/2)+1050, y: 2400 },data: {label: 'काशीराम',},...nodeDefaults},
    {id: '184',position: { x: (window.innerWidth/2)+1250, y: 2400 },data: {label: 'रामदयाल',},...nodeDefaults},
    {id: '185',position: { x: (window.innerWidth/2)+1450, y: 2400 },data: {label: 'रामगुलाम',},...nodeDefaults},

    {id: '186',position: { x: (window.innerWidth/2)+1650, y: 2400 },data: {label: 'रघु',},...nodeDefaults},
    {id: '187',position: { x: (window.innerWidth/2)+1850, y: 2400 },data: {label: 'सेवकराम',},...nodeDefaults},

    {id: '188',position: { x: (window.innerWidth/2)+1050, y: 2600 },data: {label: 'परसाराम',},...nodeDefaults},
    {id: '189',position: { x: (window.innerWidth/2)+1250, y: 2600 },data: {label: 'कुण्डल',},...nodeDefaults},
    
    {id: '190',position: { x: (window.innerWidth/2)+1550, y: 2600 },data: {label: 'अशोक',},...nodeDefaults},
    {id: '191',position: { x: (window.innerWidth/2)+1750, y: 2600 },data: {label: 'कमल',},...nodeDefaults},
    {id: '192',position: { x: (window.innerWidth/2)+1950, y: 2600 },data: {label: 'ढालसिंह',},...nodeDefaults},

    {id: '193',position: { x: (window.innerWidth/2)+1250, y: 2800 },data: {label: 'पंकज',},...nodeDefaults},
    {id: '194',position: { x: (window.innerWidth/2)+1450, y: 2800 },data: {label: 'बंटी',},...nodeDefaults},
    {id: '195',position: { x: (window.innerWidth/2)+1650, y: 2800 },data: {label: 'ओमप्रकाश',},...nodeDefaults},
    {id: '196',position: { x: (window.innerWidth/2)+1850, y: 2800 },data: {label: 'पवन',},...nodeDefaults},
    {id: '197',position: { x: (window.innerWidth/2)+2050, y: 2800 },data: {label: 'तिलोक',},...nodeDefaults},
    {id: '198',position: { x: (window.innerWidth/2)+2250, y: 2800 },data: {label: 'लोकेश',},...nodeDefaults},




    {id: '111',position: { x: (window.innerWidth/2), y: 1800 },data: {label: 'आत्माराम',},...nodeDefaults},
    {id: '112',position: { x: (window.innerWidth/2)+450, y: 2200 },data: {label: 'दादूराम',},...nodeDefaults},

    {id: '113',position: { x: (window.innerWidth/2)+350, y: 2400 },data: {label: 'भैयालाल',},...nodeDefaults},
    {id: '114',position: { x: (window.innerWidth/2)+450, y: 2600 },data: {label: 'कुंजीलाल',},...nodeDefaults},
    {id: '115',position: { x: (window.innerWidth/2)+850, y: 2400 },data: {label: 'टुंडीलाल',},...nodeDefaults},
    {id: '116',position: { x: (window.innerWidth/2)+850, y: 2600 },data: {label: 'पीतम',},...nodeDefaults},
    {id: '117',position: { x: (window.innerWidth/2)+650, y: 2600 },data: {label: 'लक्ष्मीराम',},...nodeDefaults},

    {id: '118',position: { x: (window.innerWidth/2)+100, y: 2200 },data: {label: 'जगतराय',},...nodeDefaults},
    {id: '119',position: { x: (window.innerWidth/2)-50, y: 2400 },data: {label: 'रामजीवन',},...nodeDefaults},
    {id: '120',position: { x: (window.innerWidth/2)+150, y: 2400 },data: {label: 'लक्ष्मनशा',},...nodeDefaults},
    {id: '121',position: { x: (window.innerWidth/2)+200, y: 2600 },data: {label: 'लक्ष्मीराम',},...nodeDefaults},

    {id: '122',position: { x: (window.innerWidth/2)-360, y: 2200 },data: {label: 'रूपचंद',},...nodeDefaults},
    {id: '123',position: { x: (window.innerWidth/2)-200, y: 2000 },data: {label: 'रामचंद्र',},...nodeDefaults},
    {id: '124',position: { x: (window.innerWidth/2)-250, y: 2400 },data: {label: 'दीपचंद',},...nodeDefaults},
    {id: '125',position: { x: (window.innerWidth/2)-470, y: 2000 },data: {label: 'हरिचन्द्र',},...nodeDefaults},


    {id: '126',position: { x: (window.innerWidth/2)-100, y: 2200 },data: {label: 'कुंजबिहारी',},...nodeDefaults},
    {id: '127',position: { x: (window.innerWidth/2)-560, y: 2200 },data: {label: 'टीकाराम',},...nodeDefaults},
    {id: '128',position: { x: (window.innerWidth/2)-450, y: 2400 },data: {label: 'बिहारी',},...nodeDefaults},
    {id: '129',position: { x: (window.innerWidth/2)-200, y: 2600 },data: {label: 'यामलाल',},...nodeDefaults},
    {id: '130',position: { x: (window.innerWidth/2)+0, y: 2600 },data: {label: 'राधेलाल',},...nodeDefaults},
    {id: '131',position: { x: (window.innerWidth/2)+0, y: 2800 },data: {label: 'नरेंद्र',},...nodeDefaults},

    {id: '132',position: { x: (window.innerWidth/2)-700, y: 2000 },data: {label: 'गुजोबा',},...nodeDefaults},
    {id: '133',position: { x: (window.innerWidth/2)-550, y: 1800 },data: {label: 'बुधेधु',},...nodeDefaults},

    {id: '134',position: { x: (window.innerWidth/2)-1000, y: 2000 },data: {label: 'गोपाल',},...nodeDefaults},
    {id: '135',position: { x: (window.innerWidth/2)-1400, y: 2000 },data: {label: 'गोरेलाल',},...nodeDefaults},
    {id: '136',position: { x: (window.innerWidth/2)-1800, y: 2000 },data: {label: 'मोहनलाल',},...nodeDefaults},
    {id: '137',position: { x: (window.innerWidth/2)-2400, y: 2000 },data: {label: 'जीवनलाल',},...nodeDefaults},

    {id: '138',position: { x: (window.innerWidth/2)-800, y: 2200 },data: {label: 'ईश्वर',},...nodeDefaults},
    {id: '139',position: { x: (window.innerWidth/2)-1100, y: 2200 },data: {label: 'हरिकिशन',},...nodeDefaults},
    {id: '140',position: { x: (window.innerWidth/2)-1400, y: 2200 },data: {label: 'देवीसिंह',},...nodeDefaults},
    {id: '141',position: { x: (window.innerWidth/2)-1700, y: 2200 },data: {label: 'भरत',},...nodeDefaults},
    {id: '142',position: { x: (window.innerWidth/2)-1900, y: 2200 },data: {label: 'निरपत',},...nodeDefaults},
    {id: '143',position: { x: (window.innerWidth/2)-2100, y: 2200 },data: {label: 'बाबूलाल',},...nodeDefaults},
    {id: '144',position: { x: (window.innerWidth/2)-2300, y: 2200 },data: {label: 'यादोराव',},...nodeDefaults},
    {id: '145',position: { x: (window.innerWidth/2)-2500, y: 2200 },data: {label: 'धनसिंह',},...nodeDefaults},
    {id: '146',position: { x: (window.innerWidth/2)-2800, y: 2200 },data: {label: 'सातलकराम',},...nodeDefaults},
    {id: '147',position: { x: (window.innerWidth/2)-3100, y: 2200 },data: {label: 'रेवाराम',},...nodeDefaults},

    {id: '148',position: { x: (window.innerWidth/2)-400, y: 2600 },data: {label: 'जितेंद्र',},...nodeDefaults},
    {id: '149',position: { x: (window.innerWidth/2)-600, y: 2600 },data: {label: 'तेजसिंह',},...nodeDefaults},
    {id: '150',position: { x: (window.innerWidth/2)-800, y: 2600 },data: {label: 'जगननाथ',},...nodeDefaults},
    {id: '151',position: { x: (window.innerWidth/2)-1000, y: 2600 },data: {label: 'चतुर्भुज',},...nodeDefaults},
    {id: '152',position: { x: (window.innerWidth/2)-1200, y: 2600 },data: {label: 'रामेश्वर',},...nodeDefaults},
    {id: '153',position: { x: (window.innerWidth/2)-1300, y: 2400 },data: {label: 'सावनलाल',},...nodeDefaults},
    {id: '154',position: { x: (window.innerWidth/2)-1500, y: 2400 },data: {label: 'महेश',},...nodeDefaults},
    {id: '155',position: { x: (window.innerWidth/2)-1700, y: 2400 },data: {label: 'प्रभुदयाल',},...nodeDefaults},
    {id: '156',position: { x: (window.innerWidth/2)-1900, y: 2600 },data: {label: 'गेंदसिंह',},...nodeDefaults},
    {id: '157',position: { x: (window.innerWidth/2)-2100, y: 2600 },data: {label: 'कमलसिंग',},...nodeDefaults},
    {id: '158',position: { x: (window.innerWidth/2)-2200, y: 2400 },data: {label: 'देवेन्द्र',},...nodeDefaults},
    {id: '159',position: { x: (window.innerWidth/2)-2500, y: 2600 },data: {label: 'भीखलाल',},...nodeDefaults},
    {id: '160',position: { x: (window.innerWidth/2)-2700, y: 2600 },data: {label: 'तानसिंग',},...nodeDefaults},
    {id: '161',position: { x: (window.innerWidth/2)-2900, y: 2600 },data: {label: 'जगेश्वर',},...nodeDefaults},
    {id: '162',position: { x: (window.innerWidth/2)-3100, y: 2600 },data: {label: 'जयचंद',},...nodeDefaults},
    {id: '163',position: { x: (window.innerWidth/2)-3300, y: 2600 },data: {label: 'तिलकचंद',},...nodeDefaults},
    {id: '164',position: { x: (window.innerWidth/2)-3500, y: 2600 },data: {label: 'महेताप',},...nodeDefaults},

    {id: '165',position: { x: (window.innerWidth/2)-700, y: 2800 },data: {label: 'मनोज',},...nodeDefaults},
    {id: '166',position: { x: (window.innerWidth/2)-1500, y: 2800 },data: {label: 'अभिजीत',},...nodeDefaults},
    {id: '167',position: { x: (window.innerWidth/2)-1700, y: 2800 },data: {label: 'राहुल',},...nodeDefaults},
    {id: '168',position: { x: (window.innerWidth/2)-1900, y: 2800 },data: {label: 'कोशल',},...nodeDefaults},
    {id: '169',position: { x: (window.innerWidth/2)-2100, y: 2800 },data: {label: 'खेवेन्द्र',},...nodeDefaults},
    {id: '170',position: { x: (window.innerWidth/2)-2300, y: 2800 },data: {label: 'कैलाश',},...nodeDefaults},
    {id: '171',position: { x: (window.innerWidth/2)-2500, y: 2800 },data: {label: 'हेमसिंह',},...nodeDefaults},
    {id: '172',position: { x: (window.innerWidth/2)-2700, y: 2800 },data: {label: 'भुवनेश्‍वर',},...nodeDefaults},
    {id: '173',position: { x: (window.innerWidth/2)-2900, y: 2800 },data: {label: 'सतेन्द्र',},...nodeDefaults},
    {id: '174',position: { x: (window.innerWidth/2)-3100, y: 2800 },data: {label: 'अखिलेश',},...nodeDefaults},
    {id: '175',position: { x: (window.innerWidth/2)-3300, y: 2800 },data: {label: 'राजकुमार',},...nodeDefaults},
    {id: '176',position: { x: (window.innerWidth/2)-3500, y: 2800 },data: {label: 'गेंदसिंह',},...nodeDefaults},

    {id: '177',position: { x: (window.innerWidth/2)-3100, y: 3000 },data: {label: 'समर',},...nodeDefaults},
    {id: '178',position: { x: (window.innerWidth/2)-2100, y: 3000 },data: {label: 'मुकुंद',},...nodeDefaults},





  ];
  
const initialEdges = [
    { id: 'e1-2', source: '1', target: '2' },
    { id: 'e1-3', source: '1', target: '3' },
    { id: 'e1-4', source: '1', target: '4' },
    { id: 'e1-5', source: '1', target: '5' },


    { id: 'e4-6', source: '4', target: '6' },
    { id: 'e4-7', source: '4', target: '7' },
    { id: 'e4-8', source: '4', target: '8' },  
    { id: 'e4-9', source: '4', target: '9' },
    { id: 'e4-10', source: '4', target: '10' },


    { id: 'e3-6', source: '3', target: '11' },
    { id: 'e3-7', source: '3', target: '12' },
    { id: 'e3-8', source: '3', target: '13' },  
    { id: 'e3-9', source: '3', target: '14' },


    { id: 'e179', source: '5', target: '179' },

    { id: 'e5-15', source: '179', target: '15' },
    { id: 'e5-16', source: '179', target: '16' },
    { id: 'e5-17', source: '179', target: '17' },  
    { id: 'e5-18', source: '179', target: '18' },


    { id: 'e8-19', source: '8', target: '19' },
    { id: 'e8-20', source: '8', target: '20' }, 

    { id: 'e7-21', source: '7', target: '21' },


    { id: 'e21-22', source: '21', target: '22' },
    { id: 'e21-23', source: '21', target: '23' },


    { id: 'e23-24', source: '23', target: '24' },

    { id: 'e24-25', source: '24', target: '25' },
    { id: 'e24-26', source: '24', target: '26' },


    { id: 'e22-27', source: '22', target: '27' },
    { id: 'e22-28', source: '22', target: '28' },
    { id: 'e22-29', source: '22', target: '29' },


    { id: 'e29-30', source: '28', target: '30' },
    { id: 'e29-31', source: '28', target: '31' },


    { id: 'e32', source: '20', target: '32' },


    { id: 'e33', source: '19', target: '33' },
    { id: 'e34', source: '19', target: '34' },


    { id: 'e35', source: '33', target: '35' },


    { id: 'e36', source: '35', target: '36' },
    { id: 'e37', source: '35', target: '37' },
    { id: 'e38', source: '35', target: '38' },


    { id: 'e39', source: '34', target: '39' },
    { id: 'e40', source: '34', target: '40' },
    { id: 'e41', source: '34', target: '41' },
    { id: 'e42', source: '34', target: '42' },


    { id: 'e43', source: '41', target: '43' },


    { id: 'e44', source: '40', target: '44' },


    { id: 'e45', source: '39', target: '45' },
    { id: 'e46', source: '39', target: '46' },
    { id: 'e47', source: '39', target: '47' },


    { id: 'e48', source: '17', target: '48' },
    { id: 'e49', source: '17', target: '49' },


    { id: 'e50', source: '16', target: '50' },
    { id: 'e51', source: '16', target: '51' },



    { id: 'e52', source: '15', target: '52' },
    { id: 'e53', source: '15', target: '53' },
    { id: 'e54', source: '15', target: '54' },
    { id: 'e55', source: '15', target: '55' },
    { id: 'e56', source: '15', target: '56' },


    { id: 'e57', source: '18', target: '57' },
    { id: 'e58', source: '18', target: '58' },
    { id: 'e59', source: '18', target: '59' },

    { id: 'e60', source: '58', target: '60' },


    { id: 'e61', source: '53', target: '61' },
    { id: 'e62', source: '53', target: '62' },
    { id: 'e63', source: '53', target: '63' },
    { id: 'e64', source: '53', target: '64' },


    { id: 'e65', source: '55', target: '65' },


    { id: 'e66', source: '56', target: '66' },
    { id: 'e67', source: '56', target: '67' },
    { id: 'e68', source: '56', target: '68' },

    { id: 'e69', source: '66', target: '69' },


    { id: 'e70', source: '67', target: '70' },
    { id: 'e71', source: '67', target: '71' },


    { id: 'e72', source: '65', target: '72' },
    { id: 'e73', source: '65', target: '73' },
    { id: 'e74', source: '65', target: '74' },
    { id: 'e75', source: '65', target: '75' },


    { id: 'e76', source: '63', target: '76' },
    { id: 'e77', source: '63', target: '77' },


    { id: 'e78', source: '73', target: '78' },


    { id: 'e79', source: '180', target: '79' },

    { id: 'e80', source: '12', target: '80' },

    { id: 'e81', source: '80', target: '81' },
    { id: 'e82', source: '80', target: '82' },


    { id: 'e83', source: '79', target: '83' },
    { id: 'e84', source: '79', target: '84' },
    { id: 'e85', source: '79', target: '85' },
    { id: 'e86', source: '79', target: '86' },

    { id: 'e87', source: '86', target: '87' },
    { id: 'e88', source: '86', target: '88' },
    { id: 'e89', source: '86', target: '89' },
    { id: 'e90', source: '86', target: '90' },

    { id: 'e91', source: '84', target: '91' },
    { id: 'e92', source: '84', target: '92' },

    { id: 'e93', source: '91', target: '93' },
    { id: 'e94', source: '92', target: '94' },
    { id: 'e95', source: '87', target: '95' },
    { id: 'e96', source: '87', target: '96' },
    { id: 'e97', source: '88', target: '97' },
    { id: 'e98', source: '89', target: '98' },
    { id: 'e99', source: '90', target: '99' },

    { id: 'e100', source: '82', target: '100' },
    { id: 'e101', source: '100', target: '101' },

    { id: 'e102', source: '14', target: '102' },
    { id: 'e103', source: '14', target: '103' },
    { id: 'e104', source: '14', target: '104' },
    { id: 'e105', source: '14', target: '105' },
    { id: 'e106', source: '14', target: '106' },

    { id: 'e107', source: '11', target: '107' },
    { id: 'e108', source: '11', target: '108' },

    { id: 'e109', source: '108', target: '109' },
    { id: 'e110', source: '108', target: '110' },

    { id: 'e111', source: '106', target: '111' },
    { id: 'e112', source: '111', target: '112' },

    { id: 'e113', source: '112', target: '113' },
    { id: 'e114', source: '112', target: '114' },
    { id: 'e115', source: '112', target: '115' },
    { id: 'e116', source: '112', target: '116' },
    { id: 'e117', source: '112', target: '117' },

    { id: 'e118', source: '105', target: '118' },
    { id: 'e119', source: '118', target: '119' },
    { id: 'e120', source: '118', target: '120' },
    { id: 'e121', source: '120', target: '121' },

    { id: 'e122', source: '104', target: '122' },
    { id: 'e123', source: '104', target: '123' },
    { id: 'e124', source: '104', target: '124' },
    { id: 'e125', source: '104', target: '125' },

    { id: 'e126', source: '123', target: '126' },
    { id: 'e127', source: '125', target: '127' },
    { id: 'e128', source: '122', target: '128' },
    { id: 'e129', source: '124', target: '129' },
    { id: 'e130', source: '124', target: '130' },

    { id: 'e131', source: '130', target: '131' },

    { id: 'e132', source: '103', target: '132' },
    { id: 'e133', source: '103', target: '133' },

    { id: 'e134', source: '102', target: '134' },
    { id: 'e135', source: '102', target: '135' },
    { id: 'e136', source: '102', target: '136' },
    { id: 'e137', source: '102', target: '137' },

    { id: 'e138', source: '134', target: '138' },
    { id: 'e139', source: '134', target: '139' },
    { id: 'e140', source: '134', target: '140' },
    { id: 'e141', source: '136', target: '141' },
    { id: 'e142', source: '136', target: '142' },
    { id: 'e143', source: '136', target: '143' },
    { id: 'e144', source: '136', target: '144' },
    { id: 'e145', source: '137', target: '145' },
    { id: 'e146', source: '137', target: '146' },
    { id: 'e147', source: '137', target: '147' },

    { id: 'e148', source: '138', target: '148' },
    { id: 'e149', source: '138', target: '149' },
    { id: 'e150', source: '139', target: '150' },
    { id: 'e151', source: '139', target: '151' },
    { id: 'e152', source: '139', target: '152' },
    { id: 'e153', source: '140', target: '153' },
    { id: 'e154', source: '141', target: '154' },
    { id: 'e155', source: '141', target: '155' },
    { id: 'e156', source: '142', target: '156' },
    { id: 'e157', source: '142', target: '157' },
    { id: 'e158', source: '142', target: '158' },
    { id: 'e159', source: '144', target: '159' },
    { id: 'e160', source: '145', target: '160' },
    { id: 'e161', source: '145', target: '161' },
    { id: 'e162', source: '146', target: '162' },
    { id: 'e163', source: '146', target: '163' },
    { id: 'e164', source: '147', target: '164' },

    { id: 'e165', source: '149', target: '165' },
    { id: 'e166', source: '156', target: '166' },
    { id: 'e167', source: '156', target: '167' },
    { id: 'e168', source: '157', target: '168' },
    { id: 'e169', source: '157', target: '169' },
    { id: 'e170', source: '159', target: '170' },
    { id: 'e171', source: '159', target: '171' },
    { id: 'e172', source: '160', target: '172' },
    { id: 'e173', source: '161', target: '173' },
    { id: 'e174', source: '162', target: '174' },
    { id: 'e175', source: '163', target: '175' },
    { id: 'e176', source: '164', target: '176' },

    { id: 'e177', source: '174', target: '177' },
    { id: 'e178', source: '169', target: '178' },

    { id: 'e180', source: '13', target: '180' },

    { id: 'e181', source: '107', target: '181' },
    { id: 'e182', source: '107', target: '182' },

    { id: 'e183', source: '181', target: '183' },
    { id: 'e184', source: '182', target: '184' },
    { id: 'e185', source: '182', target: '185' },

    { id: 'e186', source: '110', target: '186' },
    { id: 'e187', source: '110', target: '187' },

    { id: 'e188', source: '183', target: '188' },
    { id: 'e189', source: '183', target: '189' },

    { id: 'e190', source: '186', target: '190' },
    { id: 'e191', source: '187', target: '191' },
    { id: 'e192', source: '187', target: '192' },

    { id: 'e193', source: '190', target: '193' },
    { id: 'e194', source: '190', target: '194' },
    { id: 'e195', source: '191', target: '195' },
    { id: 'e196', source: '191', target: '196' },
    { id: 'e197', source: '191', target: '197' },
    { id: 'e198', source: '192', target: '198' },




];
 

function Hararchy() {
  const [highlightedParents, setHighlightedParents] = useState([]);
  const [highlightedChildren, setHighlightedChildren] = useState([]);
  const [highlightedNode, setHighlightedNode] = useState(null);
  const [highlightedEdges, setHighlightedEdges] = useState([]);
  // const { setViewport } = useReactFlow();
  const findParents = (nodeId) => {
    const parentNodes = [];
    let currentNodeId = nodeId;

    // Traverse up the edges to find all parent nodes
    while (true) {
      const parentEdge = initialEdges.find(edge => edge.target === currentNodeId);
      if (!parentEdge) break; // Exit if no parent edge is found
      parentNodes.push(parentEdge.source);
      currentNodeId = parentEdge.source; // Move up to the next parent
    }
    
    return parentNodes;
  };

  const onNodeClick = useCallback((event, node) => {
    // Get child nodes of the clicked node
    const children = initialNodes.filter((el) => initialEdges.some(edge => edge.source === node.id && edge.target === el.id)).map(el => el.id);
    setHighlightedChildren(children.length ? children : []); // Highlight children if present

    const parentNodes = findParents(node.id);
    setHighlightedParents(parentNodes);
    // Highlight the clicked node
    setHighlightedNode(node.id);
    const allHighlightedNodes = [node.id, ...children, ...parentNodes];
    const edgesToHighlight = initialEdges.filter(edge => allHighlightedNodes.includes(edge.source) && allHighlightedNodes.includes(edge.target)).map(edge => edge.id);
    setHighlightedEdges(edgesToHighlight);
  }, [initialNodes, initialEdges]);

  const nodeStyles = (node) => {
    if (highlightedChildren.includes(node.id)) {
      return {...nodeDefaults.style,backgroundColor: '#ffcc00', border: '1px solid #ffa500' }; // Highlight style
    }
    if (highlightedParents.includes(node.id)) {
      return {...nodeDefaults.style,backgroundColor: '#00ccff', border: '1px solid #0088cc' }; // Parent highlight style
    }
    if (highlightedNode === node.id) {
      return {...nodeDefaults.style,backgroundColor: '#f5a417', border: '1px solid #ff7300' }; // Clicked node highlight style
    }
    return { ...nodeDefaults.style}; // Default style
  };

  const styledNodes = initialNodes.map((node) => ({
    ...node,
    style: nodeStyles(node),
  }));
  const styledEdges = initialEdges.map((edge) => {
    const isHighlighted = highlightedEdges.includes(edge.id);
    return {
      ...edge,
      animated:isHighlighted ?true:false,
      style: {
        stroke: isHighlighted ? 'black' : 'black', // Highlighted edge color
        strokeWidth: isHighlighted ? 5 : 1, // Increase thickness for highlighted edges
      },
    };
  });

  const hanlescroll=()=>{
    let element=document.getElementById("start")
    element.scrollIntoView({behavior:"smooth"})
  }



  return (
    <div style={{
        width:'100vw',
        height:'100vh',
        position:'fixed',
        overflow:'scroll',
        textAlign:'center'
    }}>

        <img className='veda' style={{
            width:'50%',
            marginTop:50
        }} onClick={hanlescroll} src='veda.png' alt='img'/>


<div id='start' style={{ width: '100vw', height: '100vh'}}>
      <ReactFlow nodes={styledNodes} edges={styledEdges} onNodeClick={onNodeClick} />
    </div>
        
      
    </div>
  )
}

export default Hararchy
